/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot="true"] {
  height: inherit;
}

body {
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  /* text-align: center; */
  color: #293c4b;
  background: #F2F6FA;
}


.tabs {
  margin-bottom: -1px !important;
  position: relative;
  }
  .tab-content {
  padding: 60px;
  top: 197px;
  color: #000;
  background-color: white;
  }
  .tab-pane {
  display: none;
  }
  .tab-pane.is-active {
  display: block;
  width: 50%;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  }
  .is-active {
  display: initial;
  }
  
  .cent{
  display: block;
  margin: 10px auto;
  padding: 5px;
  border: 1px solid #ccc}
  
  .video
  {display: block;
  margin: 0 auto;}
  
  /* CSS from http://embedresponsively.com */
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }



  /* Personal */

  .has-same-height .card {
    height: 320px;
    overflow: hidden;
  }
  
  .card-content h3 {
    text-align: center;
  }
  
  .table-profile td,
  th {
    border: none !important;
  }
  
  .table-profile td:first-of-type {
    font-weight: bold;
  }
  
  .skills-content .media {
    margin: 0 !important;
    padding: 4px !important;
    border: 0 !important;
  }
  
  .skills-content .progress {
    height: 4px !important;
  }
  
  .custom-tags {
    text-align: center !important;
    display: block;
  }
  
  .portfolio-container .card {
    margin-bottom: 2em;
  }
  
  .section-heading {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 6em;
  }